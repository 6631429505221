<template>
  <div>
    <div v-if="title" class="q-mb16 u-typography-helvetica u-text u-text--s">{{ title }}</div>
    <div
      class="options flex flex-wrap--wrap"
      :class="optionsClasses"
      role="group"
      :aria-label="ariaLabel"
      data-question
      tabindex="-1"
    >
      <div
        v-for="option of options"
        :key="option.value"
        class="options__option q-mb8 full-width flex flex-column flex--align-center"
      >
        <q-checkbox
          v-if="getIsOtherOption(option)"
          :checked="getIsChecked(option.value)"
          :ai-selected="option.aiSelected"
          :disabled="getIsDisabled(option.value)"
          :dysfunction="dysfunction"
          :text="option.text"
          :tabindex="getOtherOptionTabIndex(option)"
          hide-inner-checkbox
          @change="onOtherOptionSelect(option.value, $event)"
        />
        <q-checkbox
          v-else
          :checked="getIsChecked(option.value)"
          :ai-selected="option.aiSelected"
          :disabled="getIsDisabled(option.value)"
          :text="option.text"
          :dysfunction="dysfunction"
          :hint="option.hint"
          @change="onChange(option.value, $event)"
        />
      </div>
    </div>

    <u-textarea
      v-if="isOtherInputFieldVisible"
      class="q-mt8"
      :value="otherValue"
      @input="onOtherInput"
    />
  </div>
</template>

<script>
import { get } from 'lodash';
import { UTextarea } from 'universkin-shared';

import QCheckbox from '@/modules/questionnaire/new-design-components/inputs/QCheckbox';
import QRadioButton from '@/modules/questionnaire/new-design-components/inputs/QRadioButton';

export default {
  name: 'OptionGroup',
  components: { QCheckbox, UTextarea },
  props: {
    value: {
      type: Array,
      required: true
    },
    title: {
      type: String,
      default: ''
    },
    options: {
      type: Array,
      default: () => []
    },
    twoColumns: {
      type: Boolean,
      default: false
    },
    dysfunction: {
      type: String,
      default: ''
    },
    otherValue: {
      type: String,
      default: ''
    },
    otherOption: {
      type: Object,
      default: null
    },
    maxAnswers: {
      type: Number,
      default: Infinity
    },
    ariaLabel: {
      type: String,
      default: ''
    }
  },
  computed: {
    otherOptionComponent() {
      return this.doesOtherOptionOverrideAll ? QRadioButton : QCheckbox;
    },
    optionsClasses() {
      return {
        'options--two-columns': this.twoColumns
      };
    },
    otherOptionValue() {
      return get(this.otherOption, ['value'], null);
    },
    isCheckDisabled() {
      const checkedValuesNumber = this.value.length;

      return checkedValuesNumber >= this.maxAnswers;
    },
    isOtherInputFieldVisible() {
      const groupIncludesOtherOption = this.options.find(
        ({ value }) => value === this.otherOptionValue
      );
      const isOtherSelected = this.value.includes(this.otherOptionValue);
      const shouldAskForOtherInput = get(this.otherOption, ['askForOtherInput'], false);

      return groupIncludesOtherOption && isOtherSelected && shouldAskForOtherInput;
    },
    doesOtherOptionOverrideAll() {
      return get(this.otherOption, ['overridesAll'], false);
    }
  },
  methods: {
    onChange(value, isChecked) {
      if (this.otherValue && this.doesOtherOptionOverrideAll) {
        this.resetOtherValue();
      }

      if (this.isCheckDisabled && isChecked) {
        return;
      }

      if (isChecked) {
        const otherExcluded = this.value.filter(val => val !== this.otherOptionValue);
        otherExcluded.push(value);
        const payload = this.doesOtherOptionOverrideAll ? otherExcluded : [...this.value, value];

        this.$emit('input', payload);

        return;
      }

      this.$emit(
        'input',
        this.value.filter(val => val !== value)
      );
    },
    resetOtherValue() {
      this.$emit('other-input', '');
    },
    onOtherOptionSelect(value, isChecked) {
      if (this.doesOtherOptionOverrideAll) {
        this.$emit('input', [value]);
      }

      if (!isChecked) {
        this.resetOtherValue();
        this.onChange(value, isChecked);
      }

      if (isChecked && !this.doesOtherOptionOverrideAll) {
        this.onChange(value, isChecked);
      }
    },
    onOtherInput(value) {
      this.$emit('other-input', value);
    },
    getIsChecked(value) {
      return this.value.includes(value);
    },
    getIsDisabled(value) {
      return !this.getIsChecked(value) && this.isCheckDisabled;
    },
    getIsOtherOption({ value }) {
      return this.otherOptionValue === value;
    },
    getOtherOptionTabIndex({ value }) {
      return this.getIsDisabled(value) ? '-1' : '0';
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~universkin-shared/src/assets/styles/scss/sizes.scss';
@import '~universkin-shared/src/assets/styles/scss/colors.scss';

@media (min-width: $desktop-start) {
  .options {
    &--two-columns {
      .options__option {
        max-width: calc(50% - 16px);
        flex: 0 1 calc(50% - 16px);

        &:nth-child(2n) {
          margin-left: 32px;
        }
      }

      .options__option:last-child:nth-child(odd) {
        max-width: 100%;
        flex-basis: 100%;
      }
    }
  }
}
</style>

<template>
  <div class="multi-answer-question">
    <question-title class="q-mb16 q-mt0" :class="titleClasses">{{ $t(title) }}</question-title>

    <span
      v-if="hint"
      class="multi-answer-question__hint u-typography-helvetica u-text u-text--xs q-mb8 full-width"
    >
      {{ $t(hint) }}
    </span>

    <option-group
      v-for="(optionGroup, i) of optionGroups"
      :key="i"
      :class="optionGroupClasses"
      :title="optionGroup.title"
      :aria-label="optionGroup.title || $t(title)"
      :value="value"
      :options="optionGroup.options"
      :two-columns="twoColumns"
      :dysfunction="dysfunction"
      :other-option="otherOption"
      :other-value="otherValue"
      :max-answers="maxAnswers"
      @input="onInput"
      @other-input="onOtherInput"
    />
  </div>
</template>

<script>
import QuestionTitle from '@/modules/questionnaire/new-design-components/questions/QuestionTitle';

import OptionGroup from '@/modules/questionnaire/new-design-components/questions/multi-answer-question/OptionGroup';

export default {
  name: 'MultiAnswerQuestion',
  components: { QuestionTitle, OptionGroup },
  props: {
    title: {
      type: String,
      required: true
    },
    value: {
      type: Array,
      required: true
    },
    options: {
      type: Array,
      default: () => []
    },
    groupedOptions: {
      type: Array,
      default: () => []
    },
    twoColumns: {
      type: Boolean,
      default: false
    },
    hint: {
      type: String,
      default: ''
    },
    dysfunction: {
      type: String,
      default: ''
    },
    otherValue: {
      type: String,
      default: ''
    },
    maxAnswers: {
      type: Number,
      default: Infinity
    }
  },
  computed: {
    allOptions() {
      if (this.groupedOptions.length === 0) {
        return this.options;
      }

      return this.groupedOptions.reduce((acc, { options }) => [...acc, ...options], []);
    },
    otherOption() {
      return this.allOptions.find(
        ({ overridesAll, askForOtherInput }) => overridesAll || askForOtherInput
      );
    },
    optionGroups() {
      if (this.groupedOptions.length > 0) {
        return this.groupedOptions;
      }

      return [{ options: this.options }];
    },
    hasGroupedOptions() {
      return this.groupedOptions.length > 0;
    },
    titleClasses() {
      if (this.hasGroupedOptions) {
        return 'q-mb24';
      }

      return 'q-mb16';
    },
    optionGroupClasses() {
      return {
        'multi-answer-question__option--grouped': this.hasGroupedOptions
      };
    }
  },
  methods: {
    onInput(value) {
      this.$emit('input', value);
    },
    onOtherInput(value) {
      this.$emit('other-input', value);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~universkin-shared/src/assets/styles/scss/colors';

.multi-answer-question {
  width: 100%;

  &__hint {
    color: $color-grey400;
    display: inline-block;
    text-align: center;
  }

  &__option--grouped {
    margin-bottom: 48px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>

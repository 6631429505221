<template>
  <div
    class="q-checkbox"
    :class="checkboxClasses"
    role="checkbox"
    :tabindex="disabled ? '-1' : '0'"
    :aria-checked="checked ? 'true' : 'false'"
    @click="toggleChecked"
    @keydown.space="toggleChecked"
  >
    <ai-selected-tag v-if="aiSelected" class="q-checkbox__ai-selected-tag" />
    <div class="q-checkbox__content">
      {{ textTranslated }} <info-tooltip v-if="hint" class="q-checkbox__tooltip" :hint="hint" />
    </div>

    <u-checkbox
      v-if="!hideInnerCheckbox"
      class="q-checkbox__checkbox"
      :value="checked"
      :color="dysfunction"
      :input-attrs="{ tabindex: '-1' }"
      @input="onChange"
    />
  </div>
</template>

<script>
import { UCheckbox } from 'universkin-shared';

import InfoTooltip from '@/modules/questionnaire/new-design-components/questions/InfoTooltip';
import AiSelectedTag from '@/modules/questionnaire/new-design-components/inputs/AiSelectedTag';

export default {
  name: 'QCheckbox',
  components: { UCheckbox, InfoTooltip, AiSelectedTag },
  model: {
    prop: 'checked',
    event: 'change'
  },
  props: {
    checked: {
      type: Boolean,
      default: false
    },
    dysfunction: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    hint: {
      type: String,
      default: ''
    },
    aiSelected: {
      type: Boolean,
      default: false
    },
    hideInnerCheckbox: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    checkboxClasses() {
      return {
        'q-checkbox--active': this.checked,
        [`q-checkbox--${this.dysfunction}`]: this.dysfunction,
        'q-checkbox--disabled': this.disabled
      };
    },
    textTranslated() {
      return this.$t(this.text);
    }
  },
  methods: {
    toggleChecked() {
      this.$emit('change', !this.checked);
    },
    onChange(isChecked) {
      this.$emit('change', isChecked);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~universkin-shared/src/assets/styles/scss/colors';

.q-checkbox {
  width: 100%;
  padding: 10px 32px;
  position: relative;
  box-sizing: border-box;
  border: 1px solid $color-grey200;
  background-color: transparent;
  border-radius: 40px;
  text-align: center;
  cursor: pointer;

  --active-border-color: #{$color-grey900};
  --active-background-color: #{$color-grey50};

  &--active,
  &:hover {
    border-color: var(--active-border-color);
    background-color: var(--active-background-color);
  }

  &--disabled {
    color: $color-grey200;
  }

  &--oxidative-stress {
    --active-border-color: #{$color-oxidative-stress};
    --active-background-color: #{$color-oxidative-stress-light};
  }

  &--skin-appearance {
    --active-border-color: #{$color-skin-appearance};
    --active-background-color: #{$color-skin-appearance-light};
  }

  &--skin-redness {
    --active-border-color: #{$color-skin-redness};
    --active-background-color: #{$color-skin-redness-light};
  }

  &--skin-dryness {
    --active-border-color: #{$color-skin-dryness};
    --active-background-color: #{$color-skin-dryness-light};
  }

  &--skin-oiliness {
    --active-border-color: #{$color-skin-oliness};
    --active-background-color: #{$color-skin-oliness-light};
  }

  &--skin-texture {
    --active-border-color: #{$color-skin-texture};
    --active-background-color: #{$color-skin-texture-light};
  }

  &--pimples {
    --active-border-color: #{$color-pimples};
    --active-background-color: #{$color-pimples-light};
  }

  &--skin-pigmentation {
    --active-border-color: #{$color-skin-pigmentation};
    --active-background-color: #{$color-skin-pigmentation-light};
  }

  &__ai-selected-tag {
    position: absolute;
    top: 0;
    left: 23px;
    transform: translateY(-50%);
  }

  &__checkbox {
    position: absolute;
    top: 50%;
    left: 32px;
    transform: translate(-50%, -50%);
  }

  &__content {
    font-family: var(--u-font-family-Gilroy);
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.4px;
    margin-left: 20px;
    margin-right: 8px;
  }

  &__tooltip {
    position: absolute;
    top: 50%;
    right: 5px;
    transform: translateY(-50%);
  }
}
</style>

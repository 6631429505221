<template>
  <div class="q-w360 q-fit">
    <question-with-error-wrap :error="getFieldsError(['darkCircles'])">
      <single-answer-question
        id="dark-circles"
        title="label.darkCircles"
        :value="darkCircles"
        :options="$options.yesNoResponseOptions"
        :image="$options.darkCirclesImage"
        image-alt="dark circles image"
        yes-no
        dysfunction="skin-appearance"
        @input="onFieldChange('darkCircles', $event)"
      />
    </question-with-error-wrap>

    <fade-in-transition-wrap>
      <question-with-error-wrap
        v-if="darkCircleColorsVisible"
        class="q-mt24 q-mt32-md"
        :error="getFieldsError(['darkCircleColors'])"
      >
        <multi-answer-question
          id="dark-circle-colors"
          title="label.darkCircleColors"
          :value="darkCircleColors"
          :options="$options.darkCircleColorsOptions"
          dysfunction="skin-appearance"
          @input="onFieldChange('darkCircleColors', $event)"
        />
      </question-with-error-wrap>
    </fade-in-transition-wrap>

    <fade-in-transition-wrap>
      <question-with-error-wrap
        v-if="darkCirclesChildhoodVisible"
        class="q-mt24 q-mt32-md"
        :error="getFieldsError(['darkCirclesChildhood'])"
      >
        <single-answer-question
          id="dark-circles-childhood"
          title="label.darkCirclesChildhood"
          :value="darkCirclesChildhood"
          :options="$options.yesNoResponseOptions"
          yes-no
          dysfunction="skin-appearance"
          @input="onFieldChange('darkCirclesChildhood', $event)"
        />
      </question-with-error-wrap>
    </fade-in-transition-wrap>
  </div>
</template>

<script>
import FadeInTransitionWrap from '@/components/wrappers/FadeInTransitionWrap';
import QuestionWithErrorWrap from '@/modules/questionnaire/new-design-components/questions/QuestionWithErrorWrap';
import SingleAnswerQuestion from '@/modules/questionnaire/new-design-components/questions/SingleAnswerQuestion';
import MultiAnswerQuestion from '@/modules/questionnaire/new-design-components/questions/multi-answer-question/MultiAnswerQuestion';

import { stepTemplateMixin } from '@/modules/questionnaire/mixins/stepTemplateMixin';

import { YES_NO_RESPONSE_OPTIONS } from '@/modules/questionnaire/constants/questions';
import { DARK_CIRCLE_COLORS_OPTIONS } from '@/modules/questionnaire/constants/steps/skinAppearance';

import darkCirclesImage from '@/modules/questionnaire/assets/images/skin-appearance/dark-circles/dark-circles.svg';

export default {
  name: 'DarkCirclesTemplate',
  components: {
    FadeInTransitionWrap,
    MultiAnswerQuestion,
    SingleAnswerQuestion,
    QuestionWithErrorWrap
  },
  mixins: [stepTemplateMixin],
  yesNoResponseOptions: YES_NO_RESPONSE_OPTIONS,
  darkCirclesImage,
  darkCircleColorsOptions: DARK_CIRCLE_COLORS_OPTIONS,
  props: {
    darkCircles: {
      type: String,
      required: true
    },
    darkCircleColors: {
      type: Array,
      default: () => []
    },
    darkCircleColorsVisible: {
      type: Boolean,
      default: false
    },
    darkCirclesChildhood: {
      type: String,
      default: ''
    },
    darkCirclesChildhoodVisible: {
      type: Boolean,
      default: false
    }
  }
};
</script>
